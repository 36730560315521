<template>
  <div>
    <div class="card shadow">
      <form autocomplete="off">
        <div
          class="pt-4 pb-4 pe-4 ps-4"
          @keyup.enter="$emit('getAttestatiAlboList')"
        >
          <div class="row pb-4">
            <div class="col-sm-6 filter">
              <div class="dp__input_wrap">
                <input
                  name="nome_persona"
                  type="text"
                  placeholder="Persona"
                  class="form-control"
                  autocomplete="off"
                  @input="
                    getTesserati($event.target.value);
                    setNomepersonaAttestatiAlbo($event.target.value);
                  "
                  :value="nome_persona"
                />
                <svg
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  width="32"
                  height="32"
                  viewBox="0 0 32 32"
                  class="dp__icon dp__clear_icon dp__input_icons"
                  @click="spliceTesserati()"
                >
                  <path
                    d="M23.057 7.057l-16 16c-0.52 0.52-0.52 1.365 0 1.885s1.365 0.52 1.885 0l16-16c0.52-0.52 0.52-1.365 0-1.885s-1.365-0.52-1.885 0z"
                  ></path>
                  <path
                    d="M7.057 8.943l16 16c0.52 0.52 1.365 0.52 1.885 0s0.52-1.365 0-1.885l-16-16c-0.52-0.52-1.365-0.52-1.885 0s-0.52 1.365 0 1.885z"
                  ></path>
                </svg>
              </div>
              <ListTesserati
                :list="tesseratiList"
                @hideList="resetTesserati"
                @selectedTesserato="
                  resetTesserati();
                  nome_persona = $event.name;
                  id_Persona = $event.value;
                  setId_personaAttestatiAlbo($event.value);
                  setNomepersonaAttestatiAlbo($event.name);
                "
              ></ListTesserati>
            </div>

            <div class="col-sm-3 filter">
              <!-- :min-date="new Date('2024-01-01')" -->
              <Datepicker
                v-model="dataAcqDaAttestatiAlbo"
                @update:modelValue="setdataAcqDaAttestatiAlbo($event)"
                placeholder="Data acquisizione da"
                :format="format"
                :monthChangeOnScroll="false"
                :enableTimePicker="false"
                inputClassName="h-28"
                autoApply
              />
            </div>
            <div class="col-sm-3">
              <Datepicker
                v-model="dataAcqAAttestatiAlbo"
                @update:modelValue="setdataAcqAAttestatiAlbo($event)"
                placeholder="Data acquisizione a"
                :format="format"
                :monthChangeOnScroll="false"
                :enableTimePicker="false"
                inputClassName="h-28"
                :min-date="dataAcqDaAttestatiAlbo"
                autoApply
              />
            </div>
          </div>
          <div class="row pb-4">
            <div class="col-sm-4 filter">
              <SelectInput
                :options="albi_tipo"
                name="albi_tipo"
                placeholder="Tipo albo"
                :value="idTipoAlbo"
                @changeSelect="
                  resetIdCategoriaAlbo();
                  setIdTipoAlbo($event);
                "
              />
            </div>
            <div class="col-sm-4 filter">
              <SelectInput
                :options="categorieAlbo"
                name="categorieAlbo"
                placeholder="Categoria"
                :value="idCategoriaAlbo"
                @changeSelect="setIdCategoriaAlbo($event)"
                :disabled="!idTipoAlbo"
              />
            </div>
            <div class="col-sm-4 filter">
              <SelectInput
                :options="qualificheAlbo"
                name="qualificheAlbo"
                placeholder="Qualifica"
                :value="idQualificaAlbo"
                @changeSelect="setIdQualificaAlbo($event)"
                :disabled="!idCategoriaAlbo"
              />
            </div>
          </div>
          <div class="row pb-4">
            <div class="col-sm-6 filter">
              <SelectInput
                :options="trueFalseLookup"
                name="Inviato"
                placeholder="Inviato"
                :value="id_inviato"
                @changeSelect="setIdInviato($event)"
              />
            </div>
            <div class="col-sm-6 filter">
              <SelectInput
                :options="trueFalseLookup"
                name="Scaricato"
                placeholder="Scaricato"
                :value="id_scaricato"
                @changeSelect="setIdScaricato($event)"
              />
            </div>
          </div>
          <div class="text-center mt-5">
            <button
              type="button"
              data-bs-toggle=""
              data-bs-target=""
              aria-expanded="false"
              aria-controls=""
              class="badge rounded-pill bg-light text-gray-600 ms-4"
              @click="
                setSearchStatus();
                $emit('getAttestatiAlboList');
              "
              :disabled="!loaded || !puoGenerareQualifiche"
            >
              Cerca
              <i class="bi bi-search fs-6 text-gray-600"> </i>
            </button>
            <span
              type="button"
              data-bs-toggle=""
              data-bs-target=""
              aria-expanded="false"
              aria-controls=""
              class="badge rounded-pill bg-light text-gray-600 ms-4"
              @click="
                $emit('resetFilters');
                resetCategorie();
                resetQualfiche();
              "
            >
              <i class="bi bi-arrow-clockwise fs-6 text-gray-600"> </i
            ></span>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import {
  defineComponent,
  computed,
  ref,
  watch,
  getCurrentInstance,
  onMounted,
} from "vue";
import { useStore } from "vuex";
import SelectInput from "@/components/components-fit/utility/inputs/SelectInput.vue";
import Datepicker from "vue3-date-time-picker";
import { format } from "@/composables/formatDate";
import "vue3-date-time-picker/dist/main.css";
import ListTesserati from "@/components/components-fit/utility/ListTesserati.vue";

export default defineComponent({
  name: "filtriAttestatiAlbo",
  emits: ["resetFilters", "getAttestatiAlboList"],
  props: {
    resetted: {
      type: Boolean,
    },
  },
  components: {
    SelectInput,
    ListTesserati,
    Datepicker,
  },
  setup(props) {
    const isActive = ref(true);
    const store = useStore();

    const instance = getCurrentInstance();
    const globalApi =
      instance.appContext.config.globalProperties.$apiListGlobal;
    const albi_tipo = computed(() =>
      store.getters.getStateFromName("albi_tipo")
    );
    const keys = ref("atcq");
    if (!albi_tipo.value) {
      store.dispatch("setStoreData", {
        keys: { keys: keys.value },
        apiLink: globalApi.COMBOLIST_GET,
      });
    }

    const nomeAttestatiAlbo = computed(() => store.getters.nomeAttestatiAlbo);
    const setNomeAttestatiAlbo = (e) => {
      const string = e.target.value;
      store.commit("setNomeAttestatiAlbo", string);
    };

    const cognomeAttestatiAlbo = computed(
      () => store.getters.cognomeAttestatiAlbo
    );
    const setCognomeAttestatiAlbo = (e) => {
      const string = e.target.value;
      store.commit("setCognomeAttestatiAlbo", string);
    };

    const codiceSnmAttestatiAlbo = computed(
      () => store.getters.codiceSnmAttestatiAlbo
    );
    const setCodiceSnmAttestatiAlbo = (e) => {
      const string = e.target.value;
      store.commit("setCodiceSnmAttestatiAlbo", string);
    };

    const dataAcqDaAttestatiAlbo = computed(
      () => store.getters.dataAcqDaAttestatiAlbo
    );
    const setDataAcqDaAttestatiAlbo = (event) => {
      store.commit("setDataAcqDaAttestatiAlbo", event);
    };
    const dataAcqAAttestatiAlbo = computed(
      () => store.getters.dataAcqAAttestatiAlbo
    );
    // const setDataAcqAAttestatiAlbo = (event) => {
    //   store.commit("setDataAcqAAttestatiAlbo", event);
    // };
    // const dataAcqDaAttestatiAlbo = computed(
    //   () => store.getters.dataAcqDaAttestatiAlbo
    // );
    const setdataAcqDaAttestatiAlbo = (event) => {
      store.commit("setdataAcqDaAttestatiAlbo", event);
    };
    // const dataAcqAAttestatiAlbo = computed(
    //   () => store.getters.dataAcqAAttestatiAlbo
    // );
    const setdataAcqAAttestatiAlbo = (event) => {
      store.commit("setdataAcqAAttestatiAlbo", event);
    };
    const dataFineDaAttestatiAlbo = computed(
      () => store.getters.dataFineDaAttestatiAlbo
    );
    const setDataFineDaAttestatiAlbo = (event) => {
      store.commit("setDataFineDaAttestatiAlbo", event);
    };
    const dataFineAAttestatiAlbo = computed(
      () => store.getters.dataFineAAttestatiAlbo
    );
    const setDataFineAAttestatiAlbo = (event) => {
      store.commit("setDataFineAAttestatiAlbo", event);
    };
    //societa
    const societaDenominazioneAttestatiAlbo = computed(
      () => store.getters.societaDenominazioneAttestatiAlbo
    );
    const idSocietaAttestatiAlbo = computed(
      () => store.getters.idSocietaAttestatiAlbo
    );
    const setIdSocietaAttestatiAlbo = ({ value, name }) => {
      store.commit("setIdSocietaAttestatiAlbo", value);
      store.commit("setSocietaDenominazioneAttestatiAlbo", name);
    };
    const getSocieta = (string) => {
      if (string === "") {
        store.commit("emptySocietaNomeCodAff");
        return;
      } else if (string.length < 3) return;
      store.dispatch("getSocietaNomeCodAff", string);
    };
    const resetSocieta = () => {
      store.commit("emptySocietaNomeCodAff");
    };
    //
    const verificaAttestatiAlbo = computed(
      () => store.getters.verificaAttestatiAlbo
    );
    const setVerificaAttestatiAlbo = (event) => {
      store.commit("setVerificaAttestatiAlbo", event);
    };
    const statoAttestatiAlbo = computed(() => store.getters.statoAttestatiAlbo);
    const setStatoAttestatiAlbo = (event) => {
      store.commit("setStatoAttestatiAlbo", event);
    };
    const statoAggionramentoAttestatiAlbo = computed(
      () => store.getters.statoAggionramentoAttestatiAlbo
    );
    const statoUtenzaAttestatiAlbo = computed(
      () => store.getters.statoUtenzaAttestatiAlbo
    );
    const setStatoUtenzaAttestatiAlbo = (event) => {
      store.commit("setStatoUtenzaAttestatiAlbo", event);
    };

    const categorieAttestatiAlbo = ref([]);
    const idTipoAttestatiAlbo = computed(
      () => store.getters.idTipoAttestatiAlbo
    );
    const resetIdCategoriaAttestatiAlbo = () => {
      store.commit("setIdCategoriaAttestatiAlbo", null);
    };
    const setIdTipoAttestatiAlbo = (event) => {
      if (!albi_tipo.value) return;
      const selecteTipoId = event != "" ? parseInt(event) : null;
      store.commit("setIdTipoAttestatiAlbo", selecteTipoId);
      categorieAttestatiAlbo.value.splice(
        0,
        categorieAttestatiAlbo.value.length
      );
      qualificheAttestatiAlbo.value.splice(
        0,
        qualificheAttestatiAlbo.value.length
      );
      albi_tipo.value.forEach((element) => {
        if (element.id === selecteTipoId) {
          element.categorie.forEach((cat) => {
            categorieAttestatiAlbo.value.push(cat);
          });
        }
      });
    };

    const qualificheAttestatiAlbo = ref([]);
    const idCategoriaAttestatiAlbo = computed(
      () => store.getters.idCategoriaAttestatiAlbo
    );
    const setIdCategoriaAttestatiAlbo = (event) => {
      const selecteCatId = event != "" ? parseInt(event) : null;
      store.commit("setIdCategoriaAttestatiAlbo", selecteCatId);
      qualificheAttestatiAlbo.value.splice(
        0,
        qualificheAttestatiAlbo.value.length
      );
      categorieAttestatiAlbo.value.forEach((element) => {
        if (element.id === selecteCatId) {
          element.qualifiche.forEach((qual) => {
            qualificheAttestatiAlbo.value.push(qual);
          });
        }
      });
    };

    const resetCategorie = () => {
      categorieAttestatiAlbo.value.splice(
        0,
        categorieAttestatiAlbo.value.length
      );
    };

    const idQualificaAttestatiAlbo = computed(
      () => store.getters.idQualificaAttestatiAlbo
    );
    const setIdQualificaAttestatiAlbo = (event) => {
      store.commit("setIdQualificaAttestatiAlbo", event);
    };

    const resetQualfiche = () => {
      qualificheAttestatiAlbo.value.splice(
        0,
        qualificheAttestatiAlbo.value.length
      );
    };

    const inputFocus = ref(null);

    watch(
      () => props.resetted,
      (value) => {
        if (value) {
          resetCategorie();
          resetQualfiche();
        }
      }
    );

    onMounted(() => {
      if (idTipoAlbo.value) {
        setIdTipoAlbo(idTipoAlbo.value);
      }
      if (idCategoriaAlbo.value) {
        setIdCategoriaAlbo(idCategoriaAlbo.value);
      }
      if (idQualificaAlbo.value) {
        setIdQualificaAlbo(idQualificaAlbo.value);
      }
    });

    setIdTipoAttestatiAlbo(idTipoAttestatiAlbo.value);
    setIdCategoriaAttestatiAlbo(idCategoriaAttestatiAlbo.value);

    const setStatoAggiornamentoAttestatiAlbo = (event) => {
      store.commit(
        "setStatoAggiornamentoAttestatiAlbo",
        event == 2 ? 0 : event
      );
    };
    const statoTesseramentoAttestatiAlbo = computed(
      () => store.getters.statoTesseramentoAttestatiAlbo
    );
    const setStatoTesseramentoAttestatiAlbo = (event) => {
      store.commit("setStatoTesseramentoAttestatiAlbo", event == 2 ? 0 : event);
    };

    const stagioni = computed(() => store.getters.stagioni);
    const stagioni_pagamenti_a = ref([]);

    const stagionePagamentoDa = computed(
      () => store.getters.stagionePagamentoDa
    );
    const setStagionePagamentoDa = (event) => {
      store.commit("setStagionePagamentoDa", event);
      store.commit("setStagionePagamentoA", null);
      stagioni_pagamenti_a.value = [];
      stagioni.value.forEach((element) => {
        if (element.id >= stagionePagamentoDa.value) {
          stagioni_pagamenti_a.value.push(element);
        }
      });
    };
    const stagionePagamentoA = computed(() => store.getters.stagionePagamentoA);
    const setStagionePagamentoA = (event) => {
      store.commit("setStagionePagamentoA", event);
    };

    const pagamentiNonRegistrati = computed(
      () => store.getters.pagamentiNonRegistrati
    );
    const setPagamentiNonRegistrati = (event) => {
      console.log(event);
      store.commit("setPagamentiNonRegistrati", event);
    };

    const comitati = computed(() => store.getters.getStateFromName("comitati"));
    const keysComitati = ref("c");
    if (!comitati.value) {
      store.dispatch("setStoreData", {
        keys: { keys: keysComitati.value },
        apiLink: globalApi.COMBOLIST_GET,
      });
    }

    const idComitatoRegionaleAttestatiAlbo = computed(
      () => store.getters.idComitatoRegionaleAttestatiAlbo
    );
    const setComitato = (event) => {
      store.commit("setIdComitatoRegionaleAttestatiAlbo", event);
    };

    const getTesserati = (string) => {
      store.commit("setNomeTesseratoMovimenti", string);
      if (string === "") {
        store.dispatch("svuotaPersoneNomeCognome");
        return;
      } else if (string.length < 3) return;
      store.dispatch("getPersoneNomeCognome", string);
    };
    const spliceTesserati = () => {
      setId_personaAttestatiAlbo(null);
      setNomepersonaAttestatiAlbo("");
      resetTesserati();
    };
    const resetTesserati = () => {
      store.dispatch("svuotaPersoneNomeCognome");
    };

    const setId_personaAttestatiAlbo = (event) => {
      store.commit("setId_personaAttestatiAlbo", event);
    };
    const setNomepersonaAttestatiAlbo = (event) => {
      store.commit("setNomepersonaAttestatiAlbo", event);
    };
    // FILTRI NUOVI PER QUALIFICA
    const categorieAlbo = ref([]);
    const idTipoAlbo = computed(() => store.getters.idTipoAlboAttestatiAlbo);
    const resetIdCategoriaAlbo = () => {
      store.commit("setIdCategoriaAlboAttestatiAlbo", null);
    };
    const setIdTipoAlbo = (event) => {
      console.log(event);
      if (!albi_tipo.value) return;
      const selecteTipoId = event != "" ? parseInt(event) : null;
      store.commit("setIdTipoAlboAttestatiAlbo", selecteTipoId);
      categorieAlbo.value.splice(0, categorieAlbo.value.length);
      qualificheAlbo.value.splice(0, qualificheAlbo.value.length);
      albi_tipo.value.forEach((element) => {
        if (element.id === selecteTipoId) {
          element.categorie.forEach((cat) => {
            categorieAlbo.value.push(cat);
          });
        }
      });
    };

    const qualificheAlbo = ref([]);
    const idCategoriaAlbo = computed(
      () => store.getters.idCategoriaAlboAttestatiAlbo
    );
    const setIdCategoriaAlbo = (event) => {
      const selecteCatId = event != "" ? parseInt(event) : null;
      store.commit("setIdCategoriaAlboAttestatiAlbo", selecteCatId);
      qualificheAlbo.value.splice(0, qualificheAlbo.value.length);
      categorieAlbo.value.forEach((element) => {
        if (element.id === selecteCatId) {
          element.qualifiche.forEach((qual) => {
            qualificheAlbo.value.push(qual);
          });
        }
      });
    };

    const idQualificaAlbo = computed(
      () => store.getters.idQualificaAlboAttestatiAlbo
    );
    const setIdQualificaAlbo = (event) => {
      store.commit("setIdQualificaAlboAttestatiAlbo", event);
    };
    const setIdInviato = (event) => {
      store.commit("setIdInviatoAttestatiAlbo", event);
    };
    const setIdScaricato = (event) => {
      store.commit("setIdScaricatoAttestatiAlbo", event);
    };
    const setSearchStatus = () => {
      store.commit("setSearchStatusAttestatiAlbo");
    };
    return {
      setSearchStatus,
      setIdInviato,
      setIdScaricato,
      id_inviato: computed(() => store.getters.id_inviatoAttestatiAlbo),
      id_scaricato: computed(() => store.getters.id_inviatoAttestatiAlbo),
      idTipoAlbo,
      resetIdCategoriaAlbo,
      setIdTipoAlbo,
      idCategoriaAlbo,
      setIdCategoriaAlbo,
      idQualificaAlbo,
      setIdQualificaAlbo,
      categorieAlbo,
      qualificheAlbo,

      tesseratiList: computed(() => store.getters.personeSocieta),
      nome_persona: computed(() => store.getters.nomePersonaAttestatiAlbo),
      setNomepersonaAttestatiAlbo,
      setId_personaAttestatiAlbo,
      getTesserati,
      resetTesserati,
      inputFocus,
      categorieAttestatiAlbo,
      resetCategorie,
      resetQualfiche,
      isActive,
      nomeAttestatiAlbo,
      setNomeAttestatiAlbo,
      cognomeAttestatiAlbo,
      setCognomeAttestatiAlbo,
      codiceSnmAttestatiAlbo,
      setCodiceSnmAttestatiAlbo,
      idTipoAttestatiAlbo,
      setIdTipoAttestatiAlbo,
      idCategoriaAttestatiAlbo,
      setIdCategoriaAttestatiAlbo,
      idQualificaAttestatiAlbo,
      setIdQualificaAttestatiAlbo,
      dataAcqDaAttestatiAlbo,
      setDataAcqDaAttestatiAlbo,
      dataAcqAAttestatiAlbo,
      // setDataAcqAAttestatiAlbo,
      setdataAcqDaAttestatiAlbo,
      setdataAcqAAttestatiAlbo,
      dataFineDaAttestatiAlbo,
      setDataFineDaAttestatiAlbo,
      dataFineAAttestatiAlbo,
      setDataFineAAttestatiAlbo,
      getSocieta,
      resetSocieta,
      idSocietaAttestatiAlbo,
      setIdSocietaAttestatiAlbo,
      societaDenominazioneAttestatiAlbo,
      societa: computed(() => store.getters.societaNomeCodAff),
      verificaAttestatiAlbo,
      setVerificaAttestatiAlbo,
      statoAttestatiAlbo,
      setStatoAttestatiAlbo,
      statoUtenzaAttestatiAlbo,
      setStatoUtenzaAttestatiAlbo,
      qualificheAttestatiAlbo,
      albi_tipo,
      trueFalseLookup: [
        { id: true, label: "Si" },
        { id: false, label: "No" },
      ],
      statoLookup: [
        { id: 1, label: "In regola" },
        { id: 2, label: "Non in regola" },
      ],
      statoUtenzaLookup: [
        { id: 1, label: "Non presente" },
        { id: 2, label: "Bloccata" },
        { id: 3, label: "Attiva" },
      ],
      format,
      loaded: computed(() =>
        store.getters.getStateFromName("loadedattestati_albo_list")
      ),
      resetIdCategoriaAttestatiAlbo,

      stagioni_pagamenti_a,
      stagioni,
      setStagionePagamentoDa,
      setStagionePagamentoA,
      stagionePagamentoDa,
      stagionePagamentoA,
      setPagamentiNonRegistrati,
      pagamentiNonRegistrati,
      statoAggionramentoAttestatiAlbo,
      setStatoAggiornamentoAttestatiAlbo,
      statoTesseramentoAttestatiAlbo,
      setStatoTesseramentoAttestatiAlbo,
      setComitato,
      idComitatoRegionaleAttestatiAlbo,
      comitati,
      id_persona: computed(() => store.getters.id_personaAttestatiAlbo),
      spliceTesserati,
    };
  },
  computed: {
    puoGenerareQualifiche() {
      console.log(
        "puoGenerareQualifiche",
        this.idTipoAlbo &&
          this.idCategoriaAlbo &&
          this.idQualificaAlbo &&
          this.dataAcqDaAttestatiAlbo &&
          this.dataAcqAAttestatiAlbo
      );
      return this.id_persona ? this.id_persona : this.idQualificaAlbo;
    },
  },
});
</script>

<style></style>

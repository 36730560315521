<template>
  <div>
    <FiltriAttestatiCorsi
      @getAttestatiAlboList="getAttestatiAlboList"
      @resetFilters="resetFilters"
    />
    <TableAttestatiCorsi
      @getAttestatiAlboList="getAttestatiAlboList"
      @resetFilters="resetFilters"
    />
  </div>
</template>

<script>
import {
  defineComponent,
  onMounted,
  computed,
  getCurrentInstance,
  ref,
} from "vue";
import { useStore } from "vuex";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { trimInput } from "@/composables/trimInput";

import FiltriAttestatiCorsi from "@/components/components-fit/SNM/AttestatiCorsi/FiltriAttestatiCorsi.vue";
import TableAttestatiCorsi from "@/components/components-fit/SNM/AttestatiCorsi/TableAttestatiCorsi.vue";

export default defineComponent({
  name: "AttestatiAlbo-snm",
  components: {
    FiltriAttestatiCorsi,
    TableAttestatiCorsi,
  },
  setup() {
    onMounted(() => {
      setCurrentPageBreadcrumbs("Attestati qualifiche ISF", []);
    });
    const store = useStore();

    const instance = getCurrentInstance();
    const globalApi =
      instance.appContext.config.globalProperties.$apiListGlobal;

    // const stagione = computed(() => store.getters.stagioneSelected);
    const rowsToSkip = computed(() => store.getters.rowsToSkipAttestatiAlbo);
    const fetchRows = computed(() => store.getters.fetchRowsAttestatiAlbo);
    const sortColumn = computed(() => store.getters.sortColumnAttestatiAlbo);
    const sortOrder = computed(() => store.getters.sortOrderAttestatiAlbo);

    const id_persona = computed(() => store.getters.id_personaAttestatiAlbo);
    const data_Acq_From = computed(() => store.getters.dataAcqDaAttestatiAlbo);
    const data_Acq_To = computed(() => store.getters.dataAcqAAttestatiAlbo);

    const idTipoAlboAttestatiAlbo = computed(
      () => store.getters.idTipoAlboAttestatiAlbo
    );
    const idCategoriaAlboAttestatiAlbo = computed(
      () => store.getters.idCategoriaAlboAttestatiAlbo
    );
    const idQualificaAlboAttestatiAlbo = computed(
      () => store.getters.idQualificaAlboAttestatiAlbo
    );
    const id_inviato = computed(() => store.getters.id_inviatoAttestatiAlbo);
    const id_scaricato = computed(
      () => store.getters.id_scaricatoAttestatiAlbo
    );

    const getAttestatiAlboList = () => {
      store.dispatch("setStoreListData", {
        keys: {
          id_Tipo_Albo: idTipoAlboAttestatiAlbo.value,
          id_Categoria: idCategoriaAlboAttestatiAlbo.value,
          id_Qualifica: idQualificaAlboAttestatiAlbo.value,
          data_Acq_From: data_Acq_From.value,
          data_Acq_To: data_Acq_To.value,
          id_persona: id_persona.value,
          rowstoskip: rowsToSkip.value,
          fetchrows: fetchRows.value,
          sortcolumn: sortColumn.value,
          sortorder: sortOrder.value,
          inviato: id_inviato.value,
          scaricato: id_scaricato.value,
        },
        apiLink: globalApi.ALBI_PERSONE_QUALIFICHE_GENERA_LIST,
        itemName: "attestati_albo_list",
      });
    };
    const resetted = ref(false);
    getAttestatiAlboList();

    const resetFilters = () => {
      store.commit("resetFiltersAttestatiAlbo");
      resetted.value = true;
      setTimeout(() => {
        resetted.value = false;
      }, 1000);
      getAttestatiAlboList();
    };

    const loaded = computed(() =>
      store.getters.getStateFromName("loadedattestati_albo_list")
    );

    const searchAlboList = () => {
      if (!loaded.value) return;
      store.commit("setRowsToSkipAlbo");
      getAttestatiAlboList();
    };

    return {
      getAttestatiAlboList,
      resetFilters,
      resetted,
      searchAlboList,
    };
  },
});
</script>
